/* eslint-disable no-use-before-define */
import {
  Avatar,
  Box,
  Divider,
  Drawer,
  Hidden,
  Link,
  List,
  ListSubheader,
  makeStyles,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import {
  Activity as ActivityIcon,
  BarChart as BarChartIcon,
  CreditCard as TransactionIcon,
  FileMinus as LogsIcon,
  FileText as DocsIcon,
  Gift as PromoCodesIcon,
  Globe as LanguagesIcon,
  MessageSquare as TicketsIcon,
  Package as PackageIcon,
  PieChart as PieChartIcon,
  ShoppingCart as ProductsIcon,
  Users as UsersIcon,
  Monitor as MonitorIcon,
} from 'react-feather';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Link as RouterLink, matchPath, useLocation } from 'react-router-dom';
import Logo from 'src/components/Logo';
import { GAME } from 'src/constants';
import NavItem from './NavItem';

const sections = [
  {
    subheader: 'Overview',
    items: [
      {
        title: 'Dashboard',
        icon: BarChartIcon,
        href: '/app/overview/dashboard',
      },
      {
        title: 'Activities',
        icon: ActivityIcon,
        href: '/app/overview/activities',
      },
      {
        title: 'Transactions (AppsFlyer)',
        icon: TransactionIcon,
        href: '/app/overview/transactions',
      },
      {
        title: 'Affiliates',
        icon: MonitorIcon,
        href: '/app/overview/affiliates',
      },
      {
        title: 'Charts',
        icon: PieChartIcon,
        href: '/app/overview/charts',
        items: [
          {
            title: 'Revenue',
            href: '/app/overview/charts/revenue',
          },
          {
            title: 'Monthly Recurring Revenue',
            href: '/app/overview/charts/mrr',
          },
          {
            title: 'New Trials',
            href: '/app/overview/charts/new-trials',
          },
          {
            title: 'Active Subscriber Base',
            href: '/app/overview/charts/active-subscriber-base',
          },
          {
            title: 'Trial Start Conversion',
            href: '/app/overview/charts/trial-start-conversion',
          },
          {
            title: 'Trial to Paid Conversion',
            href: '/app/overview/charts/trial-to-paid-conversion',
          },
          {
            title: 'Subscription Conversion',
            href: '/app/overview/charts/subscription-conversion',
          },
          {
            title: 'Churn',
            href: '/app/overview/charts/churn',
          },
        ],
      },
    ],
  },
  {
    subheader: 'Management',
    items: [
      {
        title: 'Users',
        icon: UsersIcon,
        href: '/app/management/users',
      },
      {
        title: 'Product Packages',
        icon: PackageIcon,
        href: '/app/management/product-packages',
      },
      {
        title: 'Products',
        icon: ProductsIcon,
        href: '/app/management/products',
      },
      {
        title: 'Promo Codes',
        icon: PromoCodesIcon,
        href: '/app/management/promo-codes',
      },
      {
        title: 'Languages',
        icon: LanguagesIcon,
        href: '/app/management/languages',
      },
      {
        title: 'Remote Config',
        icon: LanguagesIcon,
        href: '/app/management/remote-config',
      },
      {
        title: 'Lua',
        icon: LanguagesIcon,
        href: '/app/management/lua-remote-config',
      },
    ],
  },
  {
    subheader: 'Application',
    items: [
      {
        title: 'Tickets',
        href: '/app/tickets',
        icon: TicketsIcon,
      },
      {
        title: 'Logs',
        href: '/app/logs',
        icon: LogsIcon,
      },
    ],
  },
  {
    subheader: 'Forms',
    items: [
      {
        title: 'Feedback',
        href: '/app/forms/feedback',
      },
      {
        title: 'Contact Page',
        href: '/app/forms/contact-page',
      },
      {
        title: 'Career Page',
        href: '/app/forms/career-page',
      },
    ],
  },
  {
    subheader: 'Developer',
    items: [
      {
        title: 'API docs',
        icon: DocsIcon,
        href: '/docs',
      },
    ],
  },
];

function renderNavItems({ items, pathname, depth = 0 }) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceChildRoutes({ acc, item, pathname, depth }),
        [],
      )}
    </List>
  );
}

function reduceChildRoutes({ acc, pathname, item, depth }) {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false,
    });

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        open={Boolean(open)}
        title={item.title}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items,
        })}
      </NavItem>,
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        info={item.info}
        key={key}
        title={item.title}
      />,
    );
  }

  return acc;
}

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256,
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)',
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64,
  },
}));

const NavBar = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();
  const location = useLocation();

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line
  }, [location.pathname]);

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Hidden lgUp>
          <Box p={2} display="flex" justifyContent="center">
            <RouterLink to="/">
              <Logo />
            </RouterLink>
          </Box>
        </Hidden>
        <Box p={2}>
          <Box display="flex" justifyContent="center">
            <RouterLink to="/app">
              <Avatar
                alt="Tiny Minies"
                className={classes.avatar}
                src={GAME.logo}
              />
            </RouterLink>
          </Box>
          <Box mt={2} textAlign="center">
            <Link
              component={RouterLink}
              to="/app/overview"
              variant="h5"
              color="textPrimary"
              underline="none"
            >
              {GAME.name}
            </Link>
          </Box>
        </Box>
        <Divider />
        <Box p={2}>
          {sections.map(section => (
            <List
              key={section.subheader}
              subheader={
                <ListSubheader disableGutters disableSticky>
                  {section.subheader}
                </ListSubheader>
              }
            >
              {renderNavItems({
                items: section.items,
                pathname: location.pathname,
              })}
            </List>
          ))}
        </Box>
      </PerfectScrollbar>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

export default NavBar;
