import _ from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
import firebase from 'src/lib/firebase';

const collection = 'contactForms';

const initialState = {
  error: null,
  isLoaded: false,
  list: [],
  count: 0,
};

const slice = createSlice({
  name: collection,
  initialState,
  reducers: {
    get(state, action) {
      state.selected = action.payload;
    },
    all(state, action) {
      state.list = action.payload.list;
      state.count = action.payload.count;
    },
    page(state, action) {
      state.list = action.payload;
    },
    reset(state, action) {
      state.selected = null;
    },
    setError(state, action) {
      state.error = action.payload;
    },
    startLoad(state, action) {
      state.isLoaded = false;
    },
    endLoad(state, action) {
      state.isLoaded = true;
    },
  },
});

export const reducer = slice.reducer;

export const all = (limit = 100) => async dispatch => {
  dispatch(slice.actions.startLoad());
  try {
    let formQueryRef = firebase.firestore().collection(collection);

    const feedbackRef = await formQueryRef
      .orderBy('createdAt', 'desc')
      .limit(limit)
      .get();

    const collectionRef = await formQueryRef.get();

    dispatch(
      slice.actions.all({
        list: feedbackRef.docs.map(doc => _.merge({ id: doc.id }, doc.data())),
        count: collectionRef.size,
      }),
    );
  } catch (err) {
    console.error(err);
    dispatch(slice.actions.setError(err.message));
  }
  dispatch(slice.actions.endLoad());
};

export const getPage = (startAfter, limit) => async dispatch => {
  dispatch(slice.actions.startLoad());
  try {
    let formQueryRef = firebase.firestore().collection(collection);

    formQueryRef = formQueryRef.orderBy('createdAt', 'desc');

    if (startAfter) {
      formQueryRef = formQueryRef.startAfter(startAfter.createdAt);
    }

    const docsRef = await formQueryRef.limit(limit).get();

    dispatch(
      slice.actions.page(
        docsRef.docs.map(doc => _.merge({ id: doc.id }, doc.data())),
      ),
    );
  } catch (err) {
    console.error(err);
    dispatch(slice.actions.setError(err.message));
  }
  dispatch(slice.actions.endLoad());
};

export default slice;
export { collection };
