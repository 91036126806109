import { createSlice } from '@reduxjs/toolkit';
import firebase from 'src/lib/firebase';
import { now } from 'src/utils/date';

const collection = 'documents';

const initialState = {
  error: null,
  documents: null,
};

const slice = createSlice({
  name: collection,
  initialState,
  reducers: {
    get(state, action) {
      state.documents = action.payload;
    },
    setError(state, action) {
      state.error = action.payload;
    },
  },
});

export const reducer = slice.reducer;

export const update = data => async dispatch => {
  try {
    data['updatedAt'] = now();

    await firebase
      .firestore()
      .collection(collection)
      .doc('latest')
      .set(data);
  } catch (err) {
    console.error(err);
    dispatch(slice.actions.setError(err.message));
  }
};

export const get = () => async dispatch => {
  try {
    const docRef = await firebase
      .firestore()
      .collection(collection)
      .doc('latest')
      .get();

    if (docRef.exists) {
      dispatch(slice.actions.get(docRef.data()));
    } else {
      dispatch(slice.actions.setError('No such documents'));
    }
  } catch (err) {
    console.error(err);
    dispatch(slice.actions.setError(err.message));
  }
};

export default slice;
export { collection };
