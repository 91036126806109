import _ from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
import firebase from 'src/lib/firebase';

const collection = 'transactions';

const initialState = {
  error: null,
  isLoaded: false,
  list: [],
  count: 0,
};

const slice = createSlice({
  name: collection,
  initialState,
  reducers: {
    get(state, action) {
      state.selected = action.payload;
    },
    all(state, action) {
      state.list = action.payload.list;
      state.count = action.payload.count;
    },
    page(state, action) {
      state.list = action.payload;
    },
    reset(state, action) {
      state.selected = null;
    },
    setError(state, action) {
      state.error = action.payload;
    },
    startLoad(state, action) {
      state.isLoaded = false;
    },
    endLoad(state, action) {
      state.isLoaded = true;
    },
  },
});

export const reducer = slice.reducer;

export const all = (filters, limit = 100) => async dispatch => {
  dispatch(slice.actions.startLoad());
  try {
    let transactionQueryRef = firebase.firestore().collection(collection);

    if (filters.product && filters.product !== 'all') {
      transactionQueryRef = transactionQueryRef.where(
        'productId',
        '==',
        filters.product,
      );
    }

    if (filters.subscriptionEvent && filters.subscriptionEvent !== 'all') {
      transactionQueryRef = transactionQueryRef.where(
        'subscriptionEventType',
        '==',
        filters.subscriptionEvent,
      );
    }

    if (filters.country && filters.country !== 'all') {
      transactionQueryRef = transactionQueryRef.where(
        'country',
        '==',
        filters.country,
      );
    }

    const transactionsRef = await transactionQueryRef
      .orderBy('eventTime', 'desc')
      .limit(limit)
      .get();

    const collectionRef = await transactionQueryRef.get();

    dispatch(
      slice.actions.all({
        list: transactionsRef.docs.map(doc =>
          _.merge({ id: doc.id }, doc.data()),
        ),
        count: collectionRef.size,
      }),
    );
  } catch (err) {
    console.error(err);
    dispatch(slice.actions.setError(err.message));
  }
  dispatch(slice.actions.endLoad());
};

export const getPage = (startAfter, filters, limit) => async dispatch => {
  dispatch(slice.actions.startLoad());
  try {
    let transactionQueryRef = firebase.firestore().collection(collection);

    if (filters.product && filters.product !== 'all') {
      transactionQueryRef = transactionQueryRef.where(
        'productId',
        '==',
        filters.product,
      );
    }

    if (filters.country && filters.country !== 'all') {
      transactionQueryRef = transactionQueryRef.where(
        'country',
        '==',
        filters.country,
      );
    }

    if (filters.subscriptionEvent && filters.subscriptionEvent !== 'all') {
      transactionQueryRef = transactionQueryRef.where(
        'subscriptionEventType',
        '==',
        filters.subscriptionEvent,
      );
    }

    transactionQueryRef = transactionQueryRef.orderBy('eventTime', 'desc');

    if (startAfter) {
      transactionQueryRef = transactionQueryRef.startAfter(
        startAfter.eventTime,
      );
    }

    const docsRef = await transactionQueryRef.limit(limit).get();

    dispatch(
      slice.actions.page(
        docsRef.docs.map(doc => _.merge({ id: doc.id }, doc.data())),
      ),
    );
  } catch (err) {
    console.error(err);
    dispatch(slice.actions.setError(err.message));
  }
  dispatch(slice.actions.endLoad());
};

export default slice;
export { collection };
