import _ from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
import firebase from 'src/lib/firebase';
import { now } from 'src/utils/date';

const collection = 'promoCodes';

const initialState = {
  error: null,
  isLoaded: false,
  list: [],
  count: 0,
  selected: null,
};

const slice = createSlice({
  name: collection,
  initialState,
  reducers: {
    get(state, action) {
      state.selected = action.payload;
    },
    all(state, action) {
      state.list = action.payload.list;
      state.count = action.payload.count;
    },
    list(state, action) {
      state.list = action.payload;
    },
    page(state, action) {
      state.list = action.payload;
    },
    reset(state, action) {
      state.selected = null;
    },
    setError(state, action) {
      state.error = action.payload;
    },
    startLoad(state, action) {
      state.isLoaded = false;
    },
    endLoad(state, action) {
      state.isLoaded = true;
    },
  },
});

export const reducer = slice.reducer;

export const create = data => async dispatch => {
  try {
    data['createdAt'] = now();

    await firebase
      .firestore()
      .collection(collection)
      .add(data);
  } catch (err) {
    console.error(err);
    dispatch(slice.actions.setError(err.message));
  }
};

export const update = (id, data) => async dispatch => {
  try {
    data['updatedAt'] = now();

    await firebase
      .firestore()
      .collection(collection)
      .doc(id)
      .set(data);
  } catch (err) {
    console.error(err);
    dispatch(slice.actions.setError(err.message));
  }
};

export const get = id => async dispatch => {
  dispatch(slice.actions.startLoad());
  try {
    const docRef = await firebase
      .firestore()
      .collection(collection)
      .doc(id)
      .get();

    if (docRef.exists) {
      dispatch(slice.actions.get(docRef.data()));
    } else {
      dispatch(slice.actions.setError('No such promo code'));
    }
  } catch (err) {
    console.error(err);
    dispatch(slice.actions.setError(err.message));
  }
  dispatch(slice.actions.endLoad());
};

export const search = query => async dispatch => {
  dispatch(slice.actions.startLoad());
  try {
    let searchPromoCodeRef = await firebase
      .firestore()
      .collection(collection)
      .where('code', '==', query)
      .get();

    dispatch(
      slice.actions.list(
        searchPromoCodeRef.docs.map(doc => _.merge({ id: doc.id }, doc.data())),
      ),
    );
  } catch (err) {
    console.error(err);
    dispatch(slice.actions.setError(err.message));
  }
  dispatch(slice.actions.endLoad());
};

export const all = (limit = 100) => async dispatch => {
  dispatch(slice.actions.startLoad());
  try {
    const promoCodeQueryRef = await firebase.firestore().collection(collection);

    const promoCodeRef = await promoCodeQueryRef
      .orderBy('createdAt', 'desc')
      .limit(limit)
      .get();

    dispatch(
      slice.actions.all({
        list: promoCodeRef.docs.map(doc => _.merge({ id: doc.id }, doc.data())),
        count: promoCodeRef.size,
      }),
    );
  } catch (err) {
    console.error(err);
    dispatch(slice.actions.setError(err.message));
  }
  dispatch(slice.actions.endLoad());
};

// server side pagination
export const getPage = (startAfter, limit) => async dispatch => {
  dispatch(slice.actions.startLoad());
  try {
    let promoCodeRef = firebase.firestore().collection(collection);

    promoCodeRef = promoCodeRef.orderBy('createdAt', 'desc');

    if (startAfter) {
      promoCodeRef = promoCodeRef.startAfter(startAfter.createdAt);
    }

    const docsRef = await promoCodeRef.limit(limit).get();

    dispatch(
      slice.actions.page(
        docsRef.docs.map(doc => _.merge({ id: doc.id }, doc.data())),
      ),
    );
  } catch (err) {
    console.error(err);
    dispatch(slice.actions.setError(err.message));
  }
  dispatch(slice.actions.endLoad());
};

export const remove = id => async dispatch => {
  try {
    await firebase
      .firestore()
      .collection(collection)
      .doc(id)
      .delete();
  } catch (err) {
    console.error(err);
    dispatch(slice.actions.setError(err.message));
  }
  dispatch(all());
};

export const bulkRemove = ids => async dispatch => {
  try {
    ids.forEach(
      async id =>
        await firebase
          .firestore()
          .collection(collection)
          .doc(id)
          .delete(),
    );
  } catch (err) {
    console.error(err);
    dispatch(slice.actions.setError(err.message));
  }
  dispatch(all());
};

export const reset = () => async dispatch => {
  dispatch(slice.actions.reset());
};

export default slice;
export { collection };
