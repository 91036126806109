import _ from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
import firebase from 'src/lib/firebase';
import { now } from 'src/utils/date';

const collection = 'productPackages';

const initialState = {
  error: null,
  isLoaded: false,
  list: [],
  selected: null,
};

const slice = createSlice({
  name: collection,
  initialState,
  reducers: {
    get(state, action) {
      state.selected = action.payload;
    },
    all(state, action) {
      state.list = action.payload;
    },
    reset(state, action) {
      state.selected = null;
    },
    setError(state, action) {
      state.error = action.payload;
    },
    startLoad(state, action) {
      state.isLoaded = false;
    },
    endLoad(state, action) {
      state.isLoaded = true;
    },
  },
});

export const reducer = slice.reducer;

export const create = data => async dispatch => {
  try {
    data['createdAt'] = now();

    await firebase
      .firestore()
      .collection(collection)
      .add(data);
  } catch (err) {
    console.error(err);
    dispatch(slice.actions.setError(err.message));
  }
};

export const update = (id, data) => async dispatch => {
  try {
    data['updatedAt'] = now();

    await firebase
      .firestore()
      .collection(collection)
      .doc(id)
      .set(data);
  } catch (err) {
    console.error(err);
    dispatch(slice.actions.setError(err.message));
  }
};

export const get = id => async dispatch => {
  dispatch(slice.actions.startLoad());
  try {
    const docRef = await firebase
      .firestore()
      .collection(collection)
      .doc(id)
      .get();

    if (docRef.exists) {
      dispatch(slice.actions.get(docRef.data()));
    } else {
      dispatch(slice.actions.setError('No such package'));
    }
  } catch (err) {
    console.error(err);
    dispatch(slice.actions.setError(err.message));
  }
  dispatch(slice.actions.endLoad());
};

export const all = () => async dispatch => {
  dispatch(slice.actions.startLoad());
  try {
    const docsRef = await firebase
      .firestore()
      .collection(collection)
      .orderBy('createdAt', 'asc')
      .get();

    dispatch(
      slice.actions.all(
        docsRef.docs.map(doc => _.merge({ id: doc.id }, doc.data())),
      ),
    );
  } catch (err) {
    console.error(err);
    dispatch(slice.actions.setError(err.message));
  }
  dispatch(slice.actions.endLoad());
};

export const remove = id => async dispatch => {
  dispatch(slice.actions.startLoad());
  try {
    await firebase
      .firestore()
      .collection(collection)
      .doc(id)
      .delete();
  } catch (err) {
    console.error(err);
    dispatch(slice.actions.setError(err.message));
  }
  dispatch(all());
};

export const bulkRemove = ids => async dispatch => {
  dispatch(slice.actions.startLoad());
  try {
    await Promise.all(
      ids.map(async id => {
        await firebase
          .firestore()
          .collection(collection)
          .doc(id)
          .delete();
      }),
    );
  } catch (err) {
    console.error(err);
    dispatch(slice.actions.setError(err.message));
  }
  dispatch(all());
};

export const reset = () => async dispatch => {
  dispatch(slice.actions.reset());
};

export default slice;
export { collection };
