import _ from 'lodash';

export const ENABLE_REDUX_DEV_TOOLS = true;

export const THEMES = {
  LIGHT: 'LIGHT',
  ONE_DARK: 'ONE_DARK',
  UNICORN: 'UNICORN',
};

export const GAME = {
  name: 'Tiny Minies',
  logo:
    'https://is2-ssl.mzstatic.com/image/thumb/Purple114/v4/a8/0d/d4/a80dd4c8-953b-426b-e094-9253f9ed97f6/AppIcon-0-0-1x_U007emarketing-0-0-0-7-0-0-sRGB-0-0-0-GLES2_U002c0-512MB-85-220-0-0.png/230x0w.webp',
};

export const COMPANY = {
  emailCheck: {
    regex: /^[A-Za-z0-9._%+-]+@gamester.com.tr$/,
    text: 'Only authorized emails',
  },
};

export const platformOptions = [
  {
    value: 'playstore',
    text: 'PlayStore',
  },
  {
    value: 'appstore',
    text: 'AppStore',
  },
];

export const getPlatformLabel = platform => {
  return _.find(platformOptions, { value: platform }).text;
};
