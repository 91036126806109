import _ from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
import firebase from 'src/lib/firebase';

const collection = 'tickets';

const initialState = {
  error: null,
  isLoaded: true,
  list: [],
  selected: null,
};

const slice = createSlice({
  name: collection,
  initialState,
  reducers: {
    get(state, action) {
      state.selected = action.payload;
    },
    all(state, action) {
      state.list = action.payload;
    },
    reset(state, action) {
      state.selected = null;
    },
    setError(state, action) {
      state.error = action.payload;
    },
    startLoad(state, action) {
      state.isLoaded = false;
    },
    endLoad(state, action) {
      state.isLoaded = true;
    },
  },
});

export const reducer = slice.reducer;

export const get = id => async dispatch => {
  dispatch(slice.actions.startLoad());
  try {
    const docRef = await firebase
      .firestore()
      .collection(collection)
      .doc(id)
      .get();

    if (docRef.exists) {
      const messagesRef = await firebase
        .firestore()
        .collection(collection)
        .doc(id)
        .collection('messages')
        .orderBy('createdAt', 'asc')
        .get();

      dispatch(
        slice.actions.get(
          _.merge({ id: docRef.id }, docRef.data(), {
            messages: messagesRef.docs.map(message =>
              _.merge({ id: message.id }, message.data()),
            ),
          }),
        ),
      );
    } else {
      dispatch(slice.actions.setError('No such ticket'));
    }
  } catch (err) {
    console.error(err);
    dispatch(slice.actions.setError(err.message));
  }
  dispatch(slice.actions.endLoad());
};

export const all = () => async dispatch => {
  try {
    const docsRef = await firebase
      .firestore()
      .collection(collection)
      .where('isArchived', '==', false)
      .orderBy('createdAt', 'asc')
      .get();

    dispatch(
      slice.actions.all(
        docsRef.docs.map(doc => _.merge({ id: doc.id }, doc.data())),
      ),
    );
  } catch (err) {
    console.error(err);
    dispatch(slice.actions.setError(err.message));
  }
};

export const archive = id => async dispatch => {
  try {
    await firebase
      .firestore()
      .collection(collection)
      .doc(id)
      .set({ isArchived: true }, { merge: true });

    dispatch(slice.actions.reset());
  } catch (err) {
    console.error(err);
    dispatch(slice.actions.setError(err.message));
  }
  dispatch(all());
};

export const resolve = (user, id) => async dispatch => {
  try {
    await firebase
      .firestore()
      .collection(collection)
      .doc(id)
      .set({ isResolved: true, resolvedBy: user }, { merge: true });
  } catch (err) {
    console.error(err);
    dispatch(slice.actions.setError(err.message));
  }
  dispatch(all());
};

export default slice;
export { collection };
