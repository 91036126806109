import _ from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
import firebase from 'src/lib/firebase';

const collection = 'users';

const initialState = {
  list: {
    loading: 'idle',
    users: [],
    error: null,
  },
  selected: {
    isLoaded: false,
    user: null,
    error: null,
  },
};

const slice = createSlice({
  name: collection,
  initialState,
  reducers: {
    get(state, action) {
      state.selected.user = action.payload;
    },
    startGetLoad(state, action) {
      state.selected.isLoaded = false;
    },
    endGetLoad(state, action) {
      state.selected.isLoaded = true;
    },
    list(state, action) {
      state.list.users = action.payload;
    },
    startListLoad(state, action) {
      state.list.isLoaded = false;
    },
    endListLoad(state, action) {
      state.list.isLoaded = true;
    },
    reset(state, action) {
      state = initialState;
    },
    setGetError(state, action) {
      state.selected.error = action.payload;
    },
    setListError(state, action) {
      state.list.error = action.payload;
    },
  },
});

export const reducer = slice.reducer;

export const get = id => async dispatch => {
  dispatch(slice.actions.startGetLoad());
  try {
    const docRef = await firebase
      .firestore()
      .collection(collection)
      .doc(id)
      .get();

    if (docRef.exists) {
      const subscriptionsRef = await firebase
        .firestore()
        .collection(collection)
        .doc(id)
        .collection('subscriptions')
        .orderBy('created_at', 'desc')
        .get();

      const transactionsRef = await firebase
        .firestore()
        .collection(collection)
        .doc(id)
        .collection('transactions')
        .orderBy('eventTime', 'desc')
        .get();

      const userData = _.merge({ id: docRef.id }, docRef.data());

      const subscriptions = subscriptionsRef.docs.map(subscription =>
        _.merge({ id: subscription.id }, subscription.data()),
      );

      const transactions = transactionsRef.docs.map(transaction =>
        _.merge({ id: transaction.id }, transaction.data()),
      );

      dispatch(
        slice.actions.get(_.merge({ subscriptions, transactions }, userData)),
      );
    } else {
      dispatch(slice.actions.setGetError('No such user'));
    }
  } catch (err) {
    console.error(err);
    dispatch(slice.actions.setGetError(err.message));
  }
  dispatch(slice.actions.endGetLoad());
};

export const search = query => async dispatch => {
  dispatch(slice.actions.startListLoad());
  try {
    let searchUserRef = await firebase
      .firestore()
      .collection(collection)
      .where('email', '==', query)
      .get();

    if (searchUserRef.size === 0) {
      searchUserRef = await firebase
        .firestore()
        .collection(collection)
        .where('id', '==', query)
        .get();
    }

    dispatch(
      slice.actions.list(
        searchUserRef.docs.map(doc => _.merge({ id: doc.id }, doc.data())),
      ),
    );
  } catch (err) {
    console.error(err);
    dispatch(slice.actions.setListError(err.message));
  }
  dispatch(slice.actions.endListLoad());
};

export const reset = () => async dispatch => {
  dispatch(slice.actions.reset());
};

export const getUser = async userId => {
  const user = await firebase
    .firestore()
    .collection(collection)
    .doc(userId)
    .get();

  return user.exists ? user.data() : null;
};

export default slice;
export { collection };
