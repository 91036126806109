import React, { createContext, useEffect, useReducer } from 'react';
import SplashScreen from 'src/components/SplashScreen';
import firebase from 'src/lib/firebase';

const initialAuthState = {
  isAuthenticated: false,
  isInitialised: false,
  user: null,
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'AUTH_STATE_CHANGED': {
      const { isAuthenticated, user } = action.payload;

      return {
        ...state,
        isAuthenticated,
        isInitialised: true,
        user,
      };
    }
    default: {
      return { ...state };
    }
  }
};

const AuthContext = createContext({
  ...initialAuthState,
  method: 'FirebaseAuth',
  reload: () => Promise.resolve(),
  updateProfile: () => Promise.resolve(),
  createUserWithEmailAndPassword: () => Promise.resolve(),
  signInWithEmailAndPassword: () => Promise.resolve(),
  signInWithGoogle: () => Promise.resolve(),
  logout: () => Promise.resolve(),
});

export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialAuthState);

  const signInWithEmailAndPassword = (email, password) => {
    return firebase.auth().signInWithEmailAndPassword(email, password);
  };

  const signInWithGoogle = () => {
    const provider = new firebase.auth.GoogleAuthProvider();

    return firebase.auth().signInWithPopup(provider);
  };

  const createUserWithEmailAndPassword = async (email, password, name) => {
    const auth = firebase.auth();

    return auth
      .createUserWithEmailAndPassword(email, password)
      .then(() => auth.currentUser.updateProfile({ displayName: name }));
  };

  const logout = () => {
    return firebase.auth().signOut();
  };

  const reload = () => {
    return firebase.auth().currentUser.reload();
  };

  const updateProfile = profile => {
    return firebase.auth().currentUser.updateProfile(profile);
  };

  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged(authUser => {
      if (authUser) {
        const user = authUser.toJSON();
        dispatch({
          type: 'AUTH_STATE_CHANGED',
          payload: {
            isAuthenticated: true,
            user: {
              id: user.uid,
              email: user.email,
              name: user.displayName,
              phoneNumber: user.phoneNumber,
              emailVerified: user.emailVerified,
              isAnonymous: user.isAnonymous,
              createdAt: user.createdAt,
              lastLoginAt: user.lastLoginAt,
            },
          },
        });
      } else {
        dispatch({
          type: 'AUTH_STATE_CHANGED',
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    });

    return unsubscribe;
  }, [dispatch]);

  if (!state.isInitialised) {
    return <SplashScreen />;
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'FirebaseAuth',
        reload,
        updateProfile,
        createUserWithEmailAndPassword,
        signInWithEmailAndPassword,
        signInWithGoogle,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
