import _ from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
import firebase from 'src/lib/firebase';

const initialState = {
  logs: {
    isLoaded: true,
    docs: [],
  },
};

const slice = createSlice({
  name: 'storage',
  initialState,
  reducers: {
    setDocs(state, action) {
      state.logs.docs = action.payload;
      state.logs.isLoaded = true;
    },

    startLoad(state, action) {
      state.logs.isLoaded = false;
    },

    finishLoad(state, action) {
      state.logs.isLoaded = true;
    },
  },
});

const serializeFiles = result => {
  return result.reduce(
    (acc, docs) =>
      acc.concat(
        docs.items.map(doc => {
          return {
            name: doc.name,
            path: doc.location.path,
          };
        }),
      ),
    [],
  );
};

export const reducer = slice.reducer;

const logStoragePaths = ['/logs', '/logs/auth', '/logs/non-auth'];

export const searchLogs = queryList => async dispatch => {
  dispatch(slice.actions.startLoad());

  const searchablePaths = _.flatten(
    queryList.map(query => logStoragePaths.map(path => `${path}/${query}`)),
  );

  const result = await Promise.all(
    searchablePaths.map(async path => {
      return await firebase
        .storage()
        .ref(path)
        .list();
    }),
  );

  dispatch(slice.actions.setDocs(serializeFiles(result)));
  dispatch(slice.actions.finishLoad());
};

export const del = path => async dispatch => {
  dispatch(slice.actions.startLoad());

  await firebase
    .storage()
    .ref(path)
    .delete();

  dispatch(slice.actions.finishLoad());
};

export default slice;
