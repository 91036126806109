import { createSlice } from '@reduxjs/toolkit';
import firebase from 'src/lib/firebase';

const collection = 'overview';

const initialState = {
  error: null,
  dashboard: {
    isLoaded: false,
    data: null,
  },
  revenueChart: {
    isLoaded: false,
    chart: null,
  },
  trialsChart: {
    isLoaded: false,
    chart: null,
  },
  activeSubscriberBaseChart: {
    isLoaded: false,
    chart: null,
  },
  trialStartConversionChart: {
    isLoaded: false,
    chart: null,
  },
  trial2PaidConversionChart: {
    isLoaded: false,
    chart: null,
  },
  subscriptionConversionChart: {
    isLoaded: false,
    chart: null,
  },
  churnChart: {
    isLoaded: false,
    chart: null,
  },
};

const slice = createSlice({
  name: collection,
  initialState,
  reducers: {
    setDashboard(state, action) {
      state.dashboard.isLoaded = true;
      state.dashboard.data = action.payload;
    },
    setRevenueChart(state, action) {
      state.revenueChart.isLoaded = true;
      state.revenueChart.chart = action.payload;
    },
    setTrialsChart(state, action) {
      state.trialsChart.isLoaded = true;
      state.trialsChart.chart = action.payload;
    },
    setActiveSubscriberBaseChart(state, action) {
      state.activeSubscriberBaseChart.isLoaded = true;
      state.activeSubscriberBaseChart.chart = action.payload;
    },
    setTrialStartConversionChart(state, action) {
      state.trialStartConversionChart.isLoaded = true;
      state.trialStartConversionChart.chart = action.payload;
    },
    setTrial2PaidConversionChart(state, action) {
      state.trial2PaidConversionChart.isLoaded = true;
      state.trial2PaidConversionChart.chart = action.payload;
    },
    setSubscriptionConversionChart(state, action) {
      state.subscriptionConversionChart.isLoaded = true;
      state.subscriptionConversionChart.chart = action.payload;
    },
    setChurnChart(state, action) {
      state.churnChart.isLoaded = true;
      state.churnChart.chart = action.payload;
    },
    setError(state, action) {
      state.error = action.payload;
    },
  },
});

export const reducer = slice.reducer;

export const fetchDashboard = () => async dispatch => {
  try {
    const dashboard = await firebase
      .firestore()
      .collection(collection)
      .doc('dashboard')
      .get();

    dispatch(slice.actions.setDashboard(dashboard.data()));
  } catch (err) {
    console.error(err);
    dispatch(slice.actions.setError(err.message));
  }
};

export const fetchRevenueChart = () => async dispatch => {
  try {
    const revenueChartData = await firebase
      .firestore()
      .collection(collection)
      .doc('revenueChart')
      .get();

    dispatch(slice.actions.setRevenueChart(revenueChartData.data()));
  } catch (err) {
    console.error(err);
    dispatch(slice.actions.setError(err.message));
  }
};

export const fetchTrialsChart = () => async dispatch => {
  try {
    const trialsChartData = await firebase
      .firestore()
      .collection(collection)
      .doc('trialsChart')
      .get();

    dispatch(slice.actions.setTrialsChart(trialsChartData.data()));
  } catch (err) {
    console.error(err);
    dispatch(slice.actions.setError(err.message));
  }
};

export const fetchActiveSubscriberBaseChart = () => async dispatch => {
  try {
    const activeSubscriberBaseChartData = await firebase
      .firestore()
      .collection(collection)
      .doc('activeSubscriberBaseChart')
      .get();

    dispatch(
      slice.actions.setActiveSubscriberBaseChart(
        activeSubscriberBaseChartData.data(),
      ),
    );
  } catch (err) {
    console.error(err);
    dispatch(slice.actions.setError(err.message));
  }
};

export const fetchTrialStartConversionChart = () => async dispatch => {
  try {
    const trialStartConversionChartData = await firebase
      .firestore()
      .collection(collection)
      .doc('trialStartConversionChart')
      .get();

    dispatch(
      slice.actions.setTrialStartConversionChart(
        trialStartConversionChartData.data(),
      ),
    );
  } catch (err) {
    console.error(err);
    dispatch(slice.actions.setError(err.message));
  }
};

export const fetchTrial2PaidConversionChart = () => async dispatch => {
  try {
    const trial2PaidConversionChartData = await firebase
      .firestore()
      .collection(collection)
      .doc('trial2PaidConversionChart')
      .get();

    dispatch(
      slice.actions.setTrial2PaidConversionChart(
        trial2PaidConversionChartData.data(),
      ),
    );
  } catch (err) {
    console.error(err);
    dispatch(slice.actions.setError(err.message));
  }
};

export const fetchSubscriptionConversionChart = () => async dispatch => {
  try {
    const subscriptionConversionChartData = await firebase
      .firestore()
      .collection(collection)
      .doc('subscriptionConversionChart')
      .get();

    dispatch(
      slice.actions.setSubscriptionConversionChart(
        subscriptionConversionChartData.data(),
      ),
    );
  } catch (err) {
    console.error(err);
    dispatch(slice.actions.setError(err.message));
  }
};

export const fetchChurnChart = () => async dispatch => {
  try {
    const churnChartData = await firebase
      .firestore()
      .collection(collection)
      .doc('churnChart')
      .get();

    dispatch(slice.actions.setChurnChart(churnChartData.data()));
  } catch (err) {
    console.error(err);
    dispatch(slice.actions.setError(err.message));
  }
};

export default slice;
export { collection };
