export const APP_VERSION = '0.0.1';

export const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
};

export const sentryConfig = {
  dsn:
    'https://4f6572925dfa488b91dca764ff883276@o506448.ingest.sentry.io/5596168',
  traceSampleRate: 1.0,
  release: APP_VERSION,
};

export const API = {
  URL:
    process.env.NODE_ENV === 'development'
      ? 'http://localhost:8080/'
      : 'https://europe-west2-firester-d9b49.cloudfunctions.net/api',
  SAPP: 'k_2sLwQCzcoFM3',
};
