import axios from 'axios';
import firebase from 'src/lib/firebase';
import { API } from 'src/config';

const axiosInstance = axios.create({
  baseURL: API.URL,
});

axiosInstance.interceptors.request.use(async config => {
  config.headers['Accept'] = 'application/json';

  const token = await firebase.auth().currentUser.getIdToken();
  config.headers['Authorization'] = `Bearer ${token}`;

  return config;
});

axiosInstance.interceptors.response.use(
  response => response,
  error =>
    Promise.reject(
      (error.response && error.response.data) || 'Something went wrong',
    ),
);

export default axiosInstance;

const axiosNonAuthInstance = axios.create({
  baseURL: API.URL,
});

axiosNonAuthInstance.interceptors.request.use(async config => {
  config.headers['Accept'] = 'application/json';

  return config;
});

axiosNonAuthInstance.interceptors.response.use(
  response => response,
  error =>
    Promise.reject(
      (error.response && error.response.data) || 'Something went wrong',
    ),
);

export { axiosNonAuthInstance as noAuthRequest };
