import { createSlice } from '@reduxjs/toolkit';
import firebase from 'src/lib/firebase';
import moment from 'moment';
import { DATEFORMAT } from 'src/utils/date';

const collection = 'affiliate';
const DEFAULT_ROWS = {
  totalPostBack: 0,
  optIn: 0,
  successBilling: 0,
  optOut: 0,
  revenue: 0,
};

function getDaysBetweenTimestamps(timestamp1, timestamp2) {
  const startDate = moment(timestamp1);
  const endDate = moment(timestamp2);

  const daysCount = endDate.diff(startDate, 'days');

  const days = {};

  for (let i = 0; i <= daysCount; i++) {
    const currentDate = startDate.clone().add(i, 'days');
    days[currentDate.format(DATEFORMAT)] = {
      ...DEFAULT_ROWS,
      start: currentDate.startOf('day').unix() * 1000,
      end: currentDate.endOf('day').unix() * 1000,
    };
  }

  return days;
}

const initialState = {
  error: null,
  isLoaded: false,
  rows: [],
  columns: {},
  total: {},
  aggregate: {
    ...DEFAULT_ROWS,
    isLoaded: false,
  },
};

const slice = createSlice({
  name: collection,
  initialState,
  reducers: {
    setAffiliate(state, action) {
      const { rows, columns, total } = action.payload;

      state.rows = rows;
      state.columns = columns;
      state.total = total;
      state.isLoaded = true;
    },
    setAggregate(state, action) {
      state.aggregate = {
        ...action.payload,
        isLoaded: true,
      };
    },
    startLoad(state) {
      state.isLoaded = false;
    },
    startAggregateLoad(state) {
      state.aggregate.isLoaded = false;
    },
    setError(state, action) {
      state.error = action.payload;
    },
  },
});

export const reducer = slice.reducer;

export const fetchAffiliate = (
  affiliate,
  startDate,
  endDate,
) => async dispatch => {
  dispatch(slice.actions.startLoad());
  try {
    const days = getDaysBetweenTimestamps(startDate, endDate);

    for (const [date, dateData] of Object.entries(days)) {
      const { start: _start, end: _end } = dateData;
      const affiliateName = affiliate.split('PostBack')[0];

      const [
        totalPostBackRef,
        optOutRef,
        successBillingRef,
        optInRef,
      ] = await Promise.all([
        firebase
          .firestore()
          .collection(affiliate)
          .where('createdAt', '>=', _start)
          .where('createdAt', '<=', _end)
          .get(),
        firebase
          .firestore()
          .collection('zainPostBack')
          .where('affiliate', '==', affiliateName)
          .where('createdAt', '>=', _start)
          .where('createdAt', '<=', _end)
          .where('actionType', '==', '0')
          .get(),
        firebase
          .firestore()
          .collection('zainPostBack')
          .where('affiliate', '==', affiliateName)
          .where('createdAt', '>=', _start)
          .where('createdAt', '<=', _end)
          .where('actionType', '==', '2')
          .get(),
        firebase
          .firestore()
          .collection('zainPostBack')
          .where('affiliate', '==', affiliateName)
          .where('createdAt', '>=', _start)
          .where('createdAt', '<=', _end)
          .where('actionType', '==', '1')
          .get(),
      ]);

      days[date]['totalPostBack'] = totalPostBackRef.docs.length;
      days[date]['optOut'] = optOutRef.docs.length;
      days[date]['successBilling'] = successBillingRef.docs.length;
      days[date]['optIn'] = optInRef.docs.length;
      days[date]['revenue'] = days[date]['successBilling'] * 300;

      for (const [column, value] of Object.entries(days[date])) {
        if (column === 'start' || column === 'end') {
          continue;
        }

        if (!days['total']) {
          days['total'] = {
            ...DEFAULT_ROWS,
          };
        }

        days['total'][column] += value;
      }
    }

    dispatch(
      slice.actions.setAffiliate({
        rows: [
          {
            name: 'Total PostBacks',
            key: 'totalPostBack',
            data: Object.entries(days).map(([_, data]) => data.totalPostBack),
          },
          {
            name: 'Opt-in',
            key: 'optIn',
            data: Object.entries(days).map(([_, data]) => data.optIn),
          },
          {
            name: 'Success Billing',
            key: 'successBilling',
            data: Object.entries(days).map(([_, data]) => data.successBilling),
          },
          {
            name: 'Opt-out',
            key: 'optOut',
            data: Object.entries(days).map(([_, data]) => data.optOut),
          },
          {
            name: 'Revenue (IQD)',
            key: 'revenue',
            data: Object.entries(days).map(([_, data]) => data.revenue),
          },
        ],
        columns: {
          ...days,
          // make total column last
          total: days['total'],
        },
      }),
    );
  } catch (err) {
    console.error(err);
    dispatch(slice.actions.setError(err.message));
  }
};

export const calculateAggregate = (
  affiliate,
  startDate,
  endDate,
) => async dispatch => {
  dispatch(slice.actions.startAggregateLoad());

  const data = {
    ...DEFAULT_ROWS,
  };
  try {
    const totalPostBackRef = await firebase
      .firestore()
      .collection(affiliate)
      .where('createdAt', '>=', startDate)
      .where('createdAt', '<=', endDate)
      .get();

    data['totalPostBack'] = totalPostBackRef.docs.length;

    for (const postBack of totalPostBackRef.docs) {
      const postBackData = postBack.data();
      const phoneNumber = postBackData.phoneNumber;

      if (!phoneNumber) {
        continue;
      }

      const zainPostBackRef = await firebase
        .firestore()
        .collection('zainPostBack')
        .where('msisdn', '==', phoneNumber)
        .where('createdAt', '>=', startDate)
        .where('createdAt', '<=', endDate)
        .get();

      const actionTypes = zainPostBackRef.docs.map(
        doc => doc.data().actionType,
      );

      if (actionTypes.includes('0')) {
        data['optOut'] += 1;
      } else if (actionTypes.includes('2')) {
        data['successBilling'] += 1;
      } else if (actionTypes.includes('1')) {
        data['optIn'] += 1;
      }
    }

    dispatch(slice.actions.setAggregate(data));
  } catch (err) {
    console.error(err);
    dispatch(slice.actions.setError(err.message));
  }
};

export default slice;
export { collection };
