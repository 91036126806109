import _ from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
import firebase from 'src/lib/firebase';
import { now } from 'src/utils/date';

const collection = 'translations';

const initialState = {
  error: null,
  isLoaded: false,
  list: [],
  selected: null,
};

const slice = createSlice({
  name: collection,
  initialState,
  reducers: {
    get(state, action) {
      state.selected = action.payload;
    },
    all(state, action) {
      state.list = action.payload;
    },
    reset(state, action) {
      state.selected = null;
    },
    setError(state, action) {
      state.error = action.payload;
    },
    startLoad(state, action) {
      state.isLoaded = false;
    },
    endLoad(state, action) {
      state.isLoaded = true;
    },
  },
});

export const reducer = slice.reducer;

export const create = (language, data) => async dispatch => {
  try {
    data['createdAt'] = now();
    data['language'] = language;

    await firebase
      .firestore()
      .collection(collection)
      .add(data);
  } catch (err) {
    console.error(err);
    dispatch(slice.actions.setError(err.message));
  }
};

export const syncUpsert = async data => {
  try {
    const { id: translationId, ...translationData } = data;
    if (translationId) {
      translationData['updatedAt'] = now();

      await firebase
        .firestore()
        .collection(collection)
        .doc(translationId)
        .set(translationData);

      return translationId;
    } else {
      translationData['createdAt'] = now();

      const doc = await firebase
        .firestore()
        .collection(collection)
        .add(translationData);

      return doc.id;
    }
  } catch (err) {
    console.error(err);
  }
};

export const update = (language, id, data) => async dispatch => {
  try {
    data['language'] = language;
    data['updatedAt'] = now();

    await firebase
      .firestore()
      .collection(collection)
      .doc(id)
      .set(data);
  } catch (err) {
    console.error(err);
    dispatch(slice.actions.setError(err.message));
  }
};

export const get = id => async dispatch => {
  dispatch(slice.actions.startLoad());
  try {
    const docRef = await firebase
      .firestore()
      .collection(collection)
      .doc(id)
      .get();

    if (docRef.exists) {
      dispatch(slice.actions.get(_.merge({ id: docRef.id }, docRef.data())));
    } else {
      dispatch(slice.actions.setError('No such translation'));
    }
  } catch (err) {
    console.error(err);
    dispatch(slice.actions.setError(err.message));
  }
  dispatch(slice.actions.endLoad());
};

export const all = lang => async dispatch => {
  dispatch(slice.actions.startLoad());
  try {
    let docsRef = await firebase
      .firestore()
      .collection(collection)
      .where('language', '==', lang)
      .get();

    dispatch(
      slice.actions.all(
        docsRef.docs.map(doc => _.merge({ id: doc.id }, doc.data())),
      ),
    );
  } catch (err) {
    console.error(err);
    dispatch(slice.actions.setError(err.message));
  }
  dispatch(slice.actions.endLoad());
};

export const remove = (language, id) => async dispatch => {
  dispatch(slice.actions.startLoad());
  try {
    await firebase
      .firestore()
      .collection(collection)
      .doc(id)
      .delete();
  } catch (err) {
    console.error(err);
    dispatch(slice.actions.setError(err.message));
  }
  dispatch(all(language));
};

export const bulkRemove = (language, ids) => async dispatch => {
  dispatch(slice.actions.startLoad());
  try {
    ids.forEach(
      async id =>
        await firebase
          .firestore()
          .collection(collection)
          .doc(id)
          .delete(),
    );
  } catch (err) {
    console.error(err);
    dispatch(slice.actions.setError(err.message));
  }
  dispatch(all(language));
};

export const reset = () => async dispatch => {
  dispatch(slice.actions.reset());
};

export default slice;
export { collection };
